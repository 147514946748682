import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';
import ProfileDropdown from '@mulesoft/anypoint-navbar/lib/ProfileDropdown';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import ListIcon from '@mulesoft/anypoint-icons/lib/svg/list.svg';
import MuleIcon from '@mulesoft/anypoint-icons/lib/svg/mule.svg';
import NavbarMenu from '~/components/Customization/CustomizeNavbar/NavbarMenu';
import CustomizeButton from '~/components/Customization/CustomizeButton';
import styles from './CustomizeNavbar.css';

class CustomizeNavbar extends Component {
  static propTypes = {
    pages: PropTypes.array,
    organization: PropTypes.object,
    getPath: PropTypes.func,
    getOrganizationMetadata: PropTypes.func,
    isOpenMobileMenu: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isVanityDomainSession: PropTypes.bool,
    isOneTrustEnabled: PropTypes.bool,
    isOrgFederated: PropTypes.bool,
    isUserLoggedIn: PropTypes.bool,
    profile: PropTypes.object,
    customization: PropTypes.object,
    cookieConsentId: PropTypes.string,
    openMobileMenu: PropTypes.func,
    closeMobileMenu: PropTypes.func,
    onSignOut: PropTypes.func,
    openLoginOptionsModal: PropTypes.func
  };

  static defaultProps = {
    organization: {
      name: 'MuleSoft'
    },
    isOpenMobileMenu: false,
    isVanityDomainSession: false,
    isOneTrustEnabled: false,
    pages: []
  };

  state = { height: 60 };

  componentDidMount() {
    const { isUserLoggedIn, getOrganizationMetadata, organization } =
      this.props;

    if (!isUserLoggedIn) {
      getOrganizationMetadata(organization.domain);
    }
    this.updateHeight();
    global.addEventListener('resize', this.updateHeight);
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.updateHeight);
  }

  updateHeight = () => {
    if (this.bar && this.state.height !== this.bar.clientHeight) {
      this.setState({ height: this.bar.clientHeight });
    }
  };

  toggleNavSidebar = () => {
    const { isOpenMobileMenu, openMobileMenu, closeMobileMenu } = this.props;

    if (isOpenMobileMenu) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  };

  renderLogo = () => {
    const { organization } = this.props;

    const logoURL = organization.logo;

    if (logoURL) {
      return (
        <img
          src={logoURL}
          alt={`${organization.domain.replace(/-/g, ' ')} logo`}
          className={styles.logoImg}
        />
      );
    }

    return (
      <Icon size="m" data-test-id="default-icon" className={styles.logoIcon}>
        <MuleIcon />
      </Icon>
    );
  };

  handleLoginPopup = (event) => {
    const { isOrgFederated, isUserLoggedIn, openLoginOptionsModal } =
      this.props;

    if (isOrgFederated && !isUserLoggedIn) {
      event.preventDefault();
      openLoginOptionsModal();
    }
  };

  render() {
    const {
      cookieConsentId,
      closeMobileMenu,
      pages,
      profile,
      customization,
      organization,
      getPath,
      isOpenMobileMenu,
      isEditMode,
      isOneTrustEnabled,
      isVanityDomainSession,
      onSignOut
    } = this.props;

    const portalHomeURL = getPath('home', { organization }, { isEditMode });
    const customStyles = StyleSheet.create({
      navbar: {
        backgroundColor: customization.navbar.backgroundColor
      },
      loginLink: {
        color: customization.navbar.textColor,
        ':hover': {
          color: customization.navbar.textColorActive
        },
        backgroundColor: 'transparent'
      },
      toggleIcon: {
        fill: customization.navbar.textColor,
        color: customization.navbar.textColor,
        ':hover': {
          color: customization.navbar.textColorActive,
          fill: customization.navbar.textColorActive
        }
      }
    });
    const profileDropdownClassNames = {
      loginLink: css(customStyles.loginLink)
    };
    const signInLink = getPath('login', { organization });
    const href = null;
    const showCookiePreferences =
      (!isVanityDomainSession || cookieConsentId) &&
      !profile &&
      isOneTrustEnabled;

    return (
      <nav className={styles.container} data-test-id="portal-navbar">
        <div className={styles.bumper} style={{ height: this.state.height }} />
        <div
          ref={(bar) => {
            this.bar = bar;
          }}
          className={classNames(
            styles.bar,
            { [styles.barCustomize]: isEditMode },
            css(customStyles.navbar)
          )}
        >
          <div className={styles.left}>
            <button
              onClick={this.toggleNavSidebar}
              data-test-id="toogle-nav-button"
              type="button"
              className={styles.button}
            >
              <Icon
                size="s"
                data-test-id="toogle-nav-sidebar"
                className={classNames(
                  styles.toggle,
                  css(customStyles.toggleIcon)
                )}
              >
                <ListIcon />
              </Icon>
            </button>
            <Link to={portalHomeURL} className={styles.logo}>
              {this.renderLogo()}
            </Link>
            <NavbarMenu
              profile={profile}
              pages={pages}
              getPath={getPath}
              isOpenMobileMenu={isOpenMobileMenu}
              closeMobileMenu={closeMobileMenu}
              organization={organization}
              isEditMode={isEditMode}
              customization={customization}
            />
          </div>

          <div className={styles.right}>
            <a
              display-if={showCookiePreferences}
              data-test-id="nabvar-cookie-preferences"
              href={href}
              className={classNames(
                'optanon-toggle-display',
                styles.cookiePreferences,
                css(customStyles.loginLink)
              )}
            >
              Cookie Preferences
            </a>
            <CustomizeButton isEditMode={isEditMode} />
            <ProfileDropdown
              profile={profile}
              getPath={getPath}
              isOneTrustEnabled={
                isOneTrustEnabled &&
                (!isVanityDomainSession || !!cookieConsentId)
              }
              onSignout={onSignOut}
              onSignin={this.handleLoginPopup}
              signInLink={signInLink}
              classNames={profileDropdownClassNames}
            />
          </div>
        </div>
      </nav>
    );
  }
}

export default CustomizeNavbar;
